import React from 'react'

import HelpTopicGroups from './help-topic-groups'
import { useFavoriteHelpTopics } from './help-topic-list.hooks'
import HelpTopicSection, { Props as HelpTopicSectionProps } from './help-topic-section'

import Icon from 'happitu/src/components/Icon'
import { SuggestedHelpTopics } from 'happitu/src/helpers/ticket/buildSuggestedHelpTopics'
import { LocationRecord } from 'happitu/src/models/location'
import { HelpTopicPageStore, HelpTopicStore } from 'happitu/src/types/models/helpTopics'

interface Props {
  activeHelpTopic?: ID
  helpTopicPages: HelpTopicPageStore
  helpTopics: HelpTopicStore
  location?: LocationRecord
  onNavigate: (helpTopicImpressionId: ID) => void
  suggestedHelpTopics?: SuggestedHelpTopics
  tags?: TagStore
  workflowId: ID
}

const HelpTopicList = (props: Props) => {
  const favoriteHelpTopics = useFavoriteHelpTopics(props.helpTopics)

  const locationHelpTopics = React.useMemo(
    () => props.location?.helpTopicImpressionIds || [],
    [props.location?.id],
  )

  const contextualSections: Omit<
    HelpTopicSectionProps,
    'onNavigate' | 'collections'
  >[] = []

  if (props.suggestedHelpTopics) {
    contextualSections.push({
      title: 'Current Suggestions',
      titleIcon: 'light-bulb',
      defaultVisibility: true,
      settingKey: 'suggested',
      helpTopicImpressionIds: props.suggestedHelpTopics.helpTopics
        .filter(({ current }) => current)
        .map(({ helpTopicImpressionId }) => helpTopicImpressionId),
      emptyTitle: <>No suggested help topics.</>,
    })
  }

  if (props.suggestedHelpTopics) {
    contextualSections.push({
      title: 'Previously Suggested',
      titleIcon: 'stopwatch',
      defaultVisibility: false,
      settingKey: 'previously-suggested',
      helpTopicImpressionIds: props.suggestedHelpTopics.helpTopics
        .filter(({ current }) => !current)
        .map(({ helpTopicImpressionId }) => helpTopicImpressionId),
      emptyTitle: <>No suggested help topics.</>,
    })
  }

  contextualSections.push({
    title: 'Favorites',
    titleIcon: 'star-empty',
    defaultVisibility: false,
    settingKey: 'pinned',
    helpTopicImpressionIds: favoriteHelpTopics,
    emptyTitle: (
      <>
        To add favorites click the "<Icon type="star-empty" />" icon when viewing a help
        topic.
      </>
    ),
  })

  // contextualSections.push({
  //   title: 'Recently Viewed',
  //   titleIcon: 'stopwatch',
  //   defaultVisibility: false,
  //   settingKey: 'recentlyViewed',
  //   helpTopicImpressionIds: recentHelpTopics,
  //   emptyTitle: <>You have not viewed any help topics for this workflow.</>,
  // })

  if (locationHelpTopics.length > 0) {
    contextualSections.push({
      title: 'Location Help Topics',
      titleIcon: 'location',
      defaultVisibility: false,
      settingKey: 'location',
      helpTopicImpressionIds: locationHelpTopics,
      emptyTitle: <>No help topics for this location.</>,
    })
  }

  const collections = {
    helpTopics: props.helpTopics,
    helpTopicPages: props.helpTopicPages,
    tags: props.tags,
  }

  return (
    <div>
      {contextualSections.map((section, key) => (
        <HelpTopicSection
          {...section}
          key={key}
          collections={collections}
          onNavigate={props.onNavigate}
          activeHelpTopic={props.activeHelpTopic}
        />
      ))}
      <HelpTopicGroups
        collections={collections}
        onNavigate={props.onNavigate}
        activeHelpTopic={props.activeHelpTopic}
      />
    </div>
  )
}

export default HelpTopicList
