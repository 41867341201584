import styled from 'styled-components'

export const Wrapper = styled.div`
  display: grid;
  grid-template:
    'header' 4em
    'body' calc(100vh - 4em)
    / 1fr;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`

export const DocumentationWrapper = styled.div`
  padding: 1em;
`
