import React, { useMemo } from 'react'
import styled from 'styled-components'

import HelpTopicSection from './help-topic-section'

import getStyle from 'happitu/src/getStyle'
import { HelpTopicPageStore, HelpTopicStore } from 'happitu/src/types/models/helpTopics'

interface Props {
  activeHelpTopic?: ID
  collections: {
    helpTopics: HelpTopicStore
    helpTopicPages: HelpTopicPageStore
    tags?: TagStore
  }
  onNavigate: (helpTopicImpressionId: ID) => void
}

interface HelpTopicGroup {
  name: string
  color?: string
  helpTopicImpressionIds: ID[]
}

const TagPillIcon = styled.i`
  height: 10px;
  width: 10px;
  border-radius: 5px;
  display: inline-block;
  background: ${getStyle('border-color')};
  margin-right: 5px;
`

const DEFAULT_CATEGORY_KEY = 'unknown'

const HelpTopicGroups = (props: Props) => {
  const groupedHelpTopics = useMemo(() => {
    return props.collections.helpTopics.reduce<Record<string, HelpTopicGroup>>(
      (groups, helpTopic) => {
        const tag = props.collections.tags?.findById<TagRecord>(helpTopic.tagId)
        const key = helpTopic.tagId || DEFAULT_CATEGORY_KEY
        return {
          ...groups,
          [key]: {
            name: tag?.name || 'Un-categorized',
            color: tag?.color,
            helpTopicImpressionIds: [
              ...(groups[key]?.helpTopicImpressionIds || []),
              helpTopic.impressionId,
            ],
          },
        }
      },
      { [DEFAULT_CATEGORY_KEY]: { name: 'Un-categorized', helpTopicImpressionIds: [] } },
    )
  }, [props.collections.helpTopics.version])

  const sortedGroups = useMemo(
    () =>
      Object.keys(groupedHelpTopics).sort((a, b) => {
        const firstGroup = groupedHelpTopics[a]
        const secondGroup = groupedHelpTopics[b]
        if (a === DEFAULT_CATEGORY_KEY) return 1
        if (firstGroup.name < secondGroup.name) return -1
        if (firstGroup.name > secondGroup.name) return 1
        return 0
      }),
    [],
  )

  return (
    <>
      {sortedGroups.map((tagId) => {
        const { helpTopicImpressionIds, name, color } = groupedHelpTopics[tagId]
        if (helpTopicImpressionIds.length === 0) return null
        return (
          <HelpTopicSection
            title={
              <>
                <TagPillIcon style={{ backgroundColor: color }} />
                {name}
              </>
            }
            activeHelpTopic={props.activeHelpTopic}
            key={tagId}
            defaultVisibility={false}
            settingKey={tagId}
            helpTopicImpressionIds={helpTopicImpressionIds}
            collections={{
              helpTopics: props.collections.helpTopics,
              helpTopicPages: props.collections.helpTopicPages,
            }}
            onNavigate={props.onNavigate}
            emptyTitle={`No help topics for ${name}`}
          />
        )
      })}
    </>
  )
}

export default HelpTopicGroups
