import { getUserOption, setUserOption } from 'happitu/src/helpers/user'

const DEFAULT_RECENT_HELP_TOPICS = {} as Record<ID, ID[]>
const DEFAULT_PINS = {} as Record<ID, boolean>

export const getRecentHelpTopics = (workflowId: ID) =>
  getUserOption('recentHelpTopics', DEFAULT_RECENT_HELP_TOPICS)[workflowId] || []

export const getFavoriteHelpTopics = () =>
  getUserOption('favoriteHelpTopics', DEFAULT_PINS)

export const setRecentHelpTopics = (workflowId: ID, helpTopicImpressionId: ID) => {
  const recentHelpTopics = getUserOption(
    'recentHelpTopics',
    {} as UserOptions['recentHelpTopics'],
  )
  const recentWorkflowHelpTopics = workflowId ? recentHelpTopics[workflowId] || [] : []

  const filteredWorkflowHelpTopics = recentWorkflowHelpTopics
    .filter((impressionId) => impressionId !== helpTopicImpressionId)
    .filter((_, index) => index < 2)

  setUserOption('recentHelpTopics', {
    ...recentHelpTopics,
    [workflowId]: [helpTopicImpressionId, ...filteredWorkflowHelpTopics],
  })
}
