import styled from 'styled-components'
import { prop } from 'styled-tools'

import { FlexRow } from 'happitu/src/components/_DEPRECATED/Flex'
import getStyle from 'happitu/src/getStyle'

export const Footer = styled.div`
  position: fixed;
  bottom: 1em;
  left: 1em;
  right: 1em;
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
`

export const Col = styled(FlexRow)<{ justify?: string }>`
  flex-grow: 1;
  justify-content: ${prop('justify', 'center')};
`

export const TicketButtons = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const FooterNav = styled.div`
  display: inline-flex;
  width: fit-content;
  align-items-center;
  justify-self: start;
  border-radius: .25em;
  padding: 0.25em;
  background: ${getStyle('app-alt-background')}
`
