import React, { useCallback, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { ifProp } from 'styled-tools'

import ExpirationPill from 'happitu/src/components/ExpirationPill'
import Tag from 'happitu/src/components/Tag'
import Box from 'happitu/src/components/box'
import Text from 'happitu/src/components/text'
import { HelpTopicType } from 'happitu/src/constants/helpTopicConstants'
import getStyle from 'happitu/src/getStyle'
import { error } from 'happitu/src/helpers/loggerHelper'
import {
  HelpTopicRecord,
  HelpTopicPageRecord,
  HelpTopicPageStore,
} from 'happitu/src/types/models/helpTopics'

interface Props {
  isActive?: boolean
  className?: string
  helpTopic: HelpTopicRecord
  onNavigate: (helpTopicImpressionId: ID) => void
  showPreview?: boolean
  tags?: TagStore
  helpTopicPages?: HelpTopicPageStore
  previewLength?: 80
}

const HelpTopicListItem = ({ previewLength = 80, ...props }: Props) => {
  const tag = props.tags?.findById<TagRecord>(props.helpTopic.tagId)

  const preview = useMemo(() => {
    if (!props.showPreview || !props.helpTopicPages) return ''
    const firstPage = props.helpTopicPages.findById<HelpTopicPageRecord>(
      props.helpTopic.pageIds[0],
    )
    const subText = firstPage?.searchText.substr(0, previewLength)
    return `${subText}${subText && subText.length === previewLength ? '...' : ''}`
  }, [props.showPreview])

  const click = useCallback(() => {
    if (props.helpTopic.type === HelpTopicType.Link && props.helpTopicPages) {
      const firstPage = props.helpTopicPages.findById<HelpTopicPageRecord>(
        props.helpTopic.pageIds[0],
      )
      if (firstPage) {
        window.open(firstPage.contents[0].url)
      } else {
        error(`unable to load help topic link. helpTopicID: ${props.helpTopic.id}`)
      }
    } else {
      props.onNavigate(props.helpTopic.impressionId)
    }
  }, [props.helpTopic, props.helpTopicPages])

  return (
    <Box as="button" key={props.helpTopic.id} className={props.className} onClick={click}>
      <Text bold>
        {props.helpTopic.name}
        {tag && <Tag tag={tag} />}
      </Text>
      {props.showPreview && (
        <Text color="sub" style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
          {preview === 'undefined' ? '' : preview}
        </Text>
      )}
      {!!props.helpTopic.expiresAt && (
        <div>
          <ExpirationPill expiresAt={props.helpTopic.expiresAt} />
        </div>
      )}
    </Box>
  )
}

export default styled(HelpTopicListItem)`
  background: none;
  border: 0;
  border-bottom: 1px solid ${getStyle('border-color')};
  color: ${getStyle('text-default-color')};
  display: block;
  font-size: 1em;
  margin-bottom: 1em;
  padding: 1em 0;
  text-align: left;
  width: 100%;
  position: relative;

  ${ifProp(
    'isActive',
    css`
      color: ${getStyle('text-active-color')};
      padding-left: 1.5em;

      &:before {
        content: '';
        position: absolute;
        top: 1em;
        left: 0;
        bottom: 1em;
        width: 3px;
        background: ${getStyle('text-active-color')};
        border-radius: 10px;
      }
    `,
    css`
      &:active {
        color: ${getStyle('text-default-color')};
      }
    `,
  )}
`
