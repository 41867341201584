import styled from 'styled-components'

import HelpTopicListItem from './help-topic-list-item'

import Scrollable from 'happitu/src/components/Layout/Scrollable'
import { SectionHeading } from 'happitu/src/components/Section'
import { error } from 'happitu/src/helpers/loggerHelper'
import { pluralize } from 'happitu/src/helpers/stringHelper'
import { HelpTopicStore, HelpTopicRecord } from 'happitu/src/types/models/helpTopics'

interface Props {
  className?: string
  helpTopics: HelpTopicStore
  helpTopicIds: ID[]
  onNavigate: (helpTopicImpressionId: ID) => void
}

const HelpTopicSearchResults = (props: Props) => {
  const totalHelpTopics = props.helpTopicIds.length
  return (
    <Scrollable className={props.className}>
      <SectionHeading
        title={`Found ${totalHelpTopics} ${pluralize('Help Topic', totalHelpTopics)}`}
        isVisible
      />
      <div style={{ marginTop: '.5em' }}>
        {props.helpTopicIds.map((id) => {
          const helpTopic = props.helpTopics.findById<HelpTopicRecord>(id)
          if (!helpTopic) {
            error(`HelpTopicSearchResults: missing help topic in search results ${id}`)
            return null
          }
          return (
            <HelpTopicListItem
              key={id}
              helpTopic={helpTopic}
              onNavigate={props.onNavigate}
            />
          )
        })}
      </div>
    </Scrollable>
  )
}

export default styled(HelpTopicSearchResults)``
