import React, { ReactNode } from 'react'

import HelpTopicListItem from './help-topic-list-item'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { Section } from 'happitu/src/components/Section'
import {
  HelpTopicPageStore,
  HelpTopicRecord,
  HelpTopicStore,
} from 'happitu/src/types/models/helpTopics'

export interface Props {
  activeHelpTopic?: ID
  collections: {
    helpTopics: HelpTopicStore
    helpTopicPages: HelpTopicPageStore
    tags?: TagStore
  }
  defaultVisibility: boolean
  emptyTitle: ReactNode
  helpTopicImpressionIds: ID[]
  hydrator?: (helpTopicImpressionId: ID) => HelpTopicRecord | undefined
  onNavigate: (helpTopicImpressionId: ID) => void
  settingKey: string
  title: ReactNode
  titleIcon?: string
}

const HelpTopicSection = ({ hydrator, ...props }: Props) => {
  const hydrateRecord = (helpTopicId: ID) => {
    if (typeof hydrator === 'function') return hydrator(helpTopicId)
    else
      return props.collections.helpTopics.findByImpression<HelpTopicRecord>(helpTopicId)
  }

  return (
    <Section
      defaultVisibility={props.defaultVisibility}
      title={props.title}
      titleIcon={props.titleIcon}
      settingKey={`workTicket.helpTopics.${props.settingKey}`}
    >
      {() => {
        return (
          <div>
            {props.helpTopicImpressionIds.map((helpTopicImpressionId) => {
              const helpTopic = hydrateRecord(helpTopicImpressionId)
              return (
                helpTopic && (
                  <HelpTopicListItem
                    isActive={props.activeHelpTopic === helpTopicImpressionId}
                    helpTopicPages={props.collections.helpTopicPages}
                    helpTopic={helpTopic}
                    key={helpTopic.id}
                    onNavigate={props.onNavigate}
                    showPreview
                    tags={props.collections.tags}
                  />
                )
              )
            }) ?? <VoidCallout>{props.emptyTitle}</VoidCallout>}
          </div>
        )
      }}
    </Section>
  )
}

export default HelpTopicSection
