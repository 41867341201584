import { useMemo } from 'react'

import { getRecentHelpTopics, getFavoriteHelpTopics } from './help-topic-list.helpers'

import { getCurrentUser } from 'happitu/src/helpers/currentUserHelpers'
import { HelpTopicStore } from 'happitu/src/types/models/helpTopics'

export const useRecentHelpTopics = (workflowId: ID) => {
  const user = getCurrentUser()
  const raw = user?.options?.recentHelpTopics
  return useMemo(() => getRecentHelpTopics(workflowId), [workflowId, raw])
}

export const useFavoriteHelpTopics = (helpTopics: HelpTopicStore) => {
  const user = getCurrentUser()
  const raw = user?.options?.favoriteHelpTopics
  return useMemo(() => {
    const favoriteHelpTopics = getFavoriteHelpTopics()
    return Object.keys(favoriteHelpTopics).filter(
      (impressionId) =>
        favoriteHelpTopics[impressionId] && helpTopics.findByImpression(impressionId),
    )
  }, [raw])
}
