import React, { ReactNode } from 'react'

import useTicketingHelpTopicNav from '../ticketing/hooks/use-ticketing-help-topic-nav'
import TicketSubmitted from '../ticketing/views/ticket-submitted'

import LookupTicket from './views/lookup-ticket'
import NewTicket from './views/new-ticket'
import WorkTicket from './views/work-ticket'

import createRouter from 'happitu/src/createRouter'
import { TicketProvider } from 'happitu/src/modules/ticketing'
import TicketingHelpTopicsContext from 'happitu/src/modules/ticketing/ticketing-help-topics.context'


const routes = {
  baseRoute: 'portable',
  views: {
    new: NewTicket,
    work: WorkTicket,
    lookup: LookupTicket,
    success: TicketSubmitted,
  },
}

interface Props {
  children: ReactNode
  route: Router5Route
}

function PortableTicketing(props: Props) {
  const contextProps = useTicketingHelpTopicNav()
  return (
    <TicketingHelpTopicsContext.Provider value={contextProps}>
      <TicketProvider>{props.children}</TicketProvider>
    </TicketingHelpTopicsContext.Provider>
  )
}

export default createRouter(routes)(PortableTicketing)
