import React, { Suspense, useCallback, useEffect, useRef } from 'react'

import SuspendedLocationNotice from '../../ticketing/components/SuspendedLocationNotice'
import {
  Inner,
  StepViewport,
  StepWrapper,
  Wrapper,
} from '../../ticketing/components/WorkTicket/TicketBody.styles'
import { useSuspendedLocationValidation } from '../../ticketing/hooks/useSuspendedLocationValidation'

import {
  getParams,
  navigateTo,
  WorkTicketRouteParams,
} from 'happitu/src/helpers/routeHelpers'
import { getCurrentStepIndex } from 'happitu/src/helpers/ticket/ticketAttributeFinders'
import { WorkflowViewer } from 'happitu/src/modules/ticketing'


interface Props {
  stepIndex?: string
  locationId?: ID | null
  variables: Record<string, string>
}

export default function TicketingBody(props: Props) {
  const scrollableRef = useRef<HTMLDivElement>(null)
  const isSuspendedLocation = useSuspendedLocationValidation(props.locationId)

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTop = 0
    }
  }, [props.stepIndex])

  const handleAdvance = useCallback(
    (ticketInteraction: TicketInteractionRecord) => {
      if (
        ticketInteraction.id !== getParams<WorkTicketRouteParams>().ticketInteractionId
      ) {
        navigateTo('app.portable.work', {
          ...getParams(),
          ticketId: ticketInteraction.ticketId,
          ticketInteractionId: ticketInteraction.id,
          stepIndex: `0`,
          ...Object.entries(props.variables).reduce((acc, [key, value]) => {
            return {
              ...acc,
              [`var${key}`]: value,
            }
          }, {}),
        })
      } else {
        navigateTo('app.portable.work', {
          ...getParams(),
          stepIndex: `${getCurrentStepIndex(ticketInteraction.workflowSteps) + 1}`,
        })
      }
    },
    [props.variables],
  )

  return (
    <Wrapper ref={scrollableRef}>
      <Inner>
        <StepViewport>
          <StepWrapper>
            <SuspendedLocationNotice locationId={props.locationId} />
            <Suspense fallback={<div />}>
              <WorkflowViewer
                preventAdvancement={isSuspendedLocation}
                onAdvanceSuccess={handleAdvance}
              />
            </Suspense>
          </StepWrapper>
        </StepViewport>
      </Inner>
    </Wrapper>
  )
}
