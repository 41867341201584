import { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'

import SearchInput from 'happitu/src/components/Fields/SearchInput'
import getStyle from 'happitu/src/getStyle'
import useSuggester from 'happitu/src/hooks/useSuggester'
import { HelpTopicSearchResults } from 'happitu/src/modules/help-topic-list'
import { setRecentHelpTopics } from 'happitu/src/modules/help-topic-list/help-topic-list.helpers'
import { connectTicketContext, TicketComponentProps } from 'happitu/src/modules/ticketing'
import TicketingHelpTopicsContext from 'happitu/src/modules/ticketing/ticketing-help-topics.context'
import { searchHelpTopicsRequest } from 'happitu/src/services/happitu-api/helpTopicService'
import { HelpTopicRecord } from 'happitu/src/types/models/helpTopics'

type Props = TicketComponentProps<
  | 'getHelpTopic'
  | 'helpTopicPages'
  | 'helpTopics'
  | 'locations'
  | 'suggestedHelpTopics'
  | 'tags'
  | 'tickets'
  | 'users'
> &
  ExternalProps

function TicketingSearch(props: Props) {
  const [helpTopicIds, setHelpTopicIds] = useState<ID[]>([])
  const [isSearching, setIsSearching] = useState(false)

  const { advanceScreen } = useContext(TicketingHelpTopicsContext)

  const [query, _, __, setQuery] = useSuggester(async (q) => {
    if (props.workflowId && Number.isInteger(props.workflowVersion) && q !== '') {
      const newHelpTopicIds = (await searchHelpTopicsRequest(q, [], {
        limit: 1000,
        responseScope: 'ids',
        workflowIds: [`${props.workflowId}${props.workflowVersion}`],
      })) as ID[]
      setHelpTopicIds(newHelpTopicIds)
    }
  })

  const handleNavigate = (helpTopicImpressionId: ID) => {
    setIsSearching(false)
    const helpTopic =
      props.helpTopics.findByImpression<HelpTopicRecord>(helpTopicImpressionId)
    if (!helpTopic)
      throw new Error('HelpTopics: error navigating to help topic. missing help topic')

    advanceScreen(helpTopicImpressionId, helpTopic.pageIds[0])
    setRecentHelpTopics(props.workflowId, helpTopicImpressionId)
  }

  useEffect(() => {
    if (query) setIsSearching(true)
    else setIsSearching(false)
  }, [query])

  return (
    <Header>
      <SearchInput
        placeholder="Search help topics"
        loading={isSearching}
        onChange={setQuery}
        value={query}
      />
      {!!isSearching && (
        <HelpTopicSearchResults
          helpTopicIds={helpTopicIds}
          helpTopics={props.helpTopics}
          onNavigate={handleNavigate}
        />
      )}
    </Header>
  )
}

interface ExternalProps {
  workflowId: ID
  workflowVersion: number
}

const Header = styled.header`
  background: ${getStyle('app-background')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1em;
  z-index: 10;
`

export default connectTicketContext<ExternalProps>(
  ['helpTopics', 'tags', 'suggestedHelpTopics', 'helpTopicPages'],
  ['getHelpTopic'],
)(TicketingSearch)
