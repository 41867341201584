import styled from 'styled-components'

import scrollbar, {
  scrollbarXOffset,
  scrollbarYOffset,
} from 'happitu/src/themes/scrollbar'
import { media } from 'happitu/src/themes/style-utils'

export const StepWrapper = styled.div`
  bottom: 0;
  left: 50%;
  max-width: 600px;
  min-width: 300px;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  grid-area: body;
  overflow: auto;

  ${scrollbar}
  ${scrollbarYOffset}

  ${media.custom('1260px')`
    ${scrollbarXOffset}
  `}
`

export const Inner = styled.div`
  display: flex;
  padding: 1em;
  width: 100%;
`

export const StepViewport = styled.div`
  flex-grow: 1;
  min-height: 100%;
  min-width: 300px;
  position: relative;
`
