import { get } from 'lodash'
import React, { Suspense, useEffect, useState } from 'react'

import { HelpTopicsLoader } from '../../ticketing/components/HelpTopics'
import TicketingBody from '../components/ticketing-body'
import { Footer } from '../components/ticketing-footer.styles'
import TicketingSearch from '../components/ticketing-search'
import { DocumentationWrapper, Wrapper } from '../portable-ticketing.styles'

import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { setPageTitle } from 'happitu/src/helpers/domHelper'
import {
  TicketComponentProps,
  connectTicketContext,
  InitializeTicket,
  useTicketErrors,
} from 'happitu/src/modules/ticketing'

const ConflictModel = React.lazy(
  () => import(`../../ticketing/components/WorkTicket/ConflictModel`),
)
const TicketFooter = React.lazy(() => import(`../components/ticketing-footer`))
const DocumentationPane = React.lazy(
  () => import(`../../ticketing/components/WorkTicket/DocumentationPane`),
)

const HelpTopics = React.lazy(() => import('../components/help-topics'))

type Props = TicketComponentProps<
  | 'contacts'
  | 'currentContact'
  | 'currentStep'
  | 'getContact'
  | 'getInteractionFinalizeInfo'
  | 'getLocation'
  | 'getTicket'
  | 'getWorkflowHelpTopics'
  | 'initializeInteraction'
  | 'route'
  | 'ticketInteractions'
  | 'tickets'
  | 'updateContact'
  | 'updateNotes'
  | 'workflows'
  | 'variables'
>

function getHydratedTicket(ticketId?: ID, ticketInteractionId?: ID) {
  return (tickets?: TicketStore, ticketInteractions?: TicketInteractionStore) => {
    if (!tickets || !ticketInteractions) return {}
    return {
      ticket: tickets.findById<TicketRecord>(ticketId),
      ticketInteraction:
        ticketInteractions.findById<TicketInteractionRecord>(ticketInteractionId),
    }
  }
}

export function onBeforeUnload(e: BeforeUnloadEvent) {
  e.preventDefault()
  e.returnValue = 'Do you want to leave this ticket open?'
}

function setTicketWorkTitle(ticket?: TicketRecord, workflow?: WorkflowRecord) {
  if (ticket && workflow) {
    setPageTitle(`${workflow.name} - #${get(ticket, 'ticketNumber')}`)
  }
}

type View = 'workflow' | 'documentation' | 'help-topics'

function WorkTicket({
  currentStep,
  getContact,
  getInteractionFinalizeInfo,
  getLocation,
  route,
  ticketInteractions,
  tickets,
  workflows,
  variables,
}: Props) {
  const [view, setView] = useState<View>('workflow')
  const [errors, onError] = useTicketErrors()
  const { ticket, ticketInteraction } = getHydratedTicket(
    route.params.ticketId,
    route.params.ticketInteractionId,
  )(tickets, ticketInteractions)

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (ticket?.contactId) {
      getContact(ticket.contactId)
    }
  }, [ticket?.contactId])

  useEffect(() => {
    if (ticket?.locationId) {
      getLocation(ticket.locationId)
    }
  }, [ticket?.locationId])

  useEffect(() => {
    if (ticketInteraction && workflows) {
      setTicketWorkTitle(ticket, workflows.findById(ticketInteraction.workflowId))
    }
  })

  useEffect(() => {
    if (route.params.ticketInteractionId) {
      getInteractionFinalizeInfo(route.params.ticketInteractionId, {
        nextWorkflowId: currentStep?.nextWorkflowId,
      })
    }
  }, [route.params.stepIndex])

  if (errors.length > 0) {
    return <VoidCallout>{errors}</VoidCallout>
  }

  const renderView = () => {
    switch (view) {
      case 'workflow':
        return (
          <TicketingBody
            variables={variables}
            stepIndex={route.params.stepIndex}
            locationId={ticket?.locationId}
          />
        )
      case 'documentation':
        return (
          <DocumentationWrapper>
            <DocumentationPane ticket={ticket} />
          </DocumentationWrapper>
        )
      case 'help-topics':
        return (
          <Suspense fallback={<HelpTopicsLoader />}>
            {ticketInteraction && (
              <HelpTopics
                workflowId={ticketInteraction.workflowId}
                workflowVersion={ticketInteraction.workflowVersion}
              />
            )}
          </Suspense>
        )
      default:
        return <div />
    }
  }

  return (
    <InitializeTicket onError={onError}>
      <Wrapper>
        {ticketInteraction && (
          <TicketingSearch
            workflowId={ticketInteraction.workflowId}
            workflowVersion={ticketInteraction.workflowVersion}
          />
        )}
        {/* <TicketHeader
          ticket={ticket}
          workflow={workflows?.findById<WorkflowRecord>(ticketInteraction?.workflowId)}
        />
        <DocumentationWrapper>
          <CurrentContactHeader contact={currentContact} />
          <Scrollable>
            <Suspense fallback={<div />}>
              <CurrentContactDetails
                ticketId={get(ticket, 'id')}
                tickets={tickets}
                contact={currentContact}
                deps={[ticketInteractions?.version]}
                updateContact={updateContact}
                contacts={contacts}
              />
              <CurrentLocation locationId={ticket?.locationId || null} />
              <DocumentationPane ticket={ticket} />
            </Suspense>
          </Scrollable>
          <AgentNotes
            defaultValue={get(ticketInteraction, 'notes', '')}
            onBlur={updateNotes}
          />
        </DocumentationWrapper> */}

        {renderView()}

        <Suspense fallback={<Footer />}>
          <TicketFooter
            ticket={ticket}
            ticketInteraction={ticketInteraction}
            onSelectTab={setView}
            view={view}
          />
        </Suspense>
        {/* <ErrorBoundary errorComponent={HelpTopicErrorBoundary}>
          <Suspense fallback={<HelpTopicsLoader />}>
            {ticketInteraction?.workflowId ? (
              <HelpTopics
                workflowId={ticketInteraction?.workflowId}
                workflowVersion={ticketInteraction?.workflowVersion}
              />
            ) : (
              <div />
            )}
          </Suspense>
        </ErrorBoundary> */}
      </Wrapper>
      <Suspense fallback={<div />}>
        <ConflictModel />
      </Suspense>
    </InitializeTicket>
  )
}

export default connectTicketContext(
  [
    'contacts?',
    'currentContact?',
    'currentStep?',
    'route',
    'ticketInteractions?',
    'tickets?',
    'workflows?',
    'workflowStepGroupSteps?',
    'variables?',
  ],
  [
    'getContact',
    'getInteractionFinalizeInfo',
    'getLocation',
    'getWorkflowStepGroups',
    'updateContact',
    'updateNotes',
  ],
)(WorkTicket)
