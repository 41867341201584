import * as React from 'react'

interface Config {
  cursor?: string | null
  limit?: number
  defaultSort?: string
  defaultValue?: string
  searchOnMount?: boolean
  [key: string]: any
}

const DEFAULT_VALUES = {
  defaultValue: '',
} as Config

export default function useSuggester(
  searchHandler: (...args: any[]) => Promise<any> | void,
  config: Config = DEFAULT_VALUES,
  delay = 150,
): [string, string | undefined, boolean, typeof handleChange, typeof handleSort] {
  const { defaultValue, defaultSort, searchOnMount, ...handlerConfig } = config
  const canSearch = React.useRef(searchOnMount !== undefined ? searchOnMount : true)
  const [text, setText] = React.useState(defaultValue || '')
  const [sort, setSort] = React.useState(defaultSort || '')
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (!searchOnMount && !canSearch.current) {
      canSearch.current = true
      return
    }
    const sanitizedText = text.trim()
    if (sanitizedText !== '') setLoading(true)
    const handler = setTimeout(async () => {
      await searchHandler(
        sanitizedText,
        sort && sanitizedText === '' ? JSON.parse(sort) : null,
        {
          ...handlerConfig,
          cursor: '*',
        },
      )
      setLoading(false)
    }, delay)
    return () => clearTimeout(handler)
  }, [text, sort])

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setText(e.currentTarget.value)
  }

  function handleSort(value: string) {
    setSort(value)
  }

  return [text, sort, loading, handleChange, handleSort]
}
