import { get } from 'lodash'
import React, { Suspense, useEffect } from 'react'

import { CurrentContactHeader } from '../components/CurrentContact'
import { HelpTopicErrorBoundary, HelpTopicsLoader } from '../components/HelpTopics'
import AgentNotes from '../components/WorkTicket/AgentNotes'
import TicketBody from '../components/WorkTicket/TicketBody'
import { Footer } from '../components/WorkTicket/TicketFooter.styles'
import TicketHeader from '../components/WorkTicket/TicketHeader'
import { DocumentationWrapper, Wrapper } from '../ticketing.styles'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import Scrollable from 'happitu/src/components/Layout/Scrollable'
import VoidCallout from 'happitu/src/components/Layout/VoidCallout'
import { setPageTitle } from 'happitu/src/helpers/domHelper'
import {
  TicketComponentProps,
  connectTicketContext,
  InitializeTicket,
  useTicketErrors,
} from 'happitu/src/modules/ticketing'

const ConflictModel = React.lazy(() => import(`../components/WorkTicket/ConflictModel`))
const TicketFooter = React.lazy(() => import(`../components/WorkTicket/TicketFooter`))
const DocumentationPane = React.lazy(
  () => import(`../components/WorkTicket/DocumentationPane`),
)

const CurrentContactDetails = React.lazy(
  () => import('../components/CurrentContact/CurrentContactDetails'),
)
const CurrentLocation = React.lazy(() => import('../components/CurrentLocation'))
const HelpTopics = React.lazy(() => import('../components/HelpTopics/HelpTopics'))

type Props = TicketComponentProps<
  | 'contacts'
  | 'currentContact'
  | 'currentStep'
  | 'getContact'
  | 'getInteractionFinalizeInfo'
  | 'getLocation'
  | 'getTicket'
  | 'getWorkflowHelpTopics'
  | 'initializeInteraction'
  | 'route'
  | 'ticketInteractions'
  | 'tickets'
  | 'updateContact'
  | 'updateNotes'
  | 'workflows'
  | 'variables'
>

function getHydratedTicket(ticketId?: ID, ticketInteractionId?: ID) {
  return (tickets?: TicketStore, ticketInteractions?: TicketInteractionStore) => {
    if (!tickets || !ticketInteractions) return {}
    return {
      ticket: tickets.findById<TicketRecord>(ticketId),
      ticketInteraction:
        ticketInteractions.findById<TicketInteractionRecord>(ticketInteractionId),
    }
  }
}

export function onBeforeUnload(e: BeforeUnloadEvent) {
  e.preventDefault()
  e.returnValue = 'Do you want to leave this ticket open?'
}

function setTicketWorkTitle(ticket?: TicketRecord, workflow?: WorkflowRecord) {
  if (ticket && workflow) {
    setPageTitle(`${workflow.name} - #${get(ticket, 'ticketNumber')}`)
  }
}

function WorkTicket({
  contacts,
  currentContact,
  currentStep,
  getContact,
  getInteractionFinalizeInfo,
  getLocation,
  route,
  ticketInteractions,
  tickets,
  updateContact,
  updateNotes,
  workflows,
  variables,
}: Props) {
  const [errors, onError] = useTicketErrors()
  const { ticket, ticketInteraction } = getHydratedTicket(
    route.params.ticketId,
    route.params.ticketInteractionId,
  )(tickets, ticketInteractions)

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (ticket?.contactId) {
      getContact(ticket.contactId)
    }
  }, [ticket?.contactId])

  useEffect(() => {
    if (ticket?.locationId) {
      getLocation(ticket.locationId)
    }
  }, [ticket?.locationId])

  useEffect(() => {
    if (ticketInteraction && workflows) {
      setTicketWorkTitle(ticket, workflows.findById(ticketInteraction.workflowId))
    }
  })

  useEffect(() => {
    if (route.params.ticketInteractionId) {
      getInteractionFinalizeInfo(route.params.ticketInteractionId, {
        nextWorkflowId: currentStep?.nextWorkflowId,
      })
    }
  }, [route.params.stepIndex])

  if (errors.length > 0) {
    return <VoidCallout>{errors}</VoidCallout>
  }

  return (
    <InitializeTicket onError={onError}>
      <Wrapper>
        <TicketHeader
          ticket={ticket}
          workflow={workflows?.findById<WorkflowRecord>(ticketInteraction?.workflowId)}
        />
        <DocumentationWrapper>
          <CurrentContactHeader contact={currentContact} />
          <Scrollable>
            <Suspense fallback={<div />}>
              <CurrentContactDetails
                ticketId={get(ticket, 'id')}
                tickets={tickets}
                contact={currentContact}
                deps={[ticketInteractions?.version]}
                updateContact={updateContact}
                contacts={contacts}
              />
              <CurrentLocation locationId={ticket?.locationId || null} />
              <DocumentationPane ticket={ticket} />
            </Suspense>
          </Scrollable>
          <AgentNotes
            defaultValue={get(ticketInteraction, 'notes', '')}
            onBlur={updateNotes}
          />
        </DocumentationWrapper>
        <TicketBody
          variables={variables}
          stepIndex={route.params.stepIndex}
          locationId={ticket?.locationId}
        />
        <Suspense fallback={<Footer />}>
          <TicketFooter ticket={ticket} ticketInteraction={ticketInteraction} />
        </Suspense>
        <ErrorBoundary errorComponent={HelpTopicErrorBoundary}>
          <Suspense fallback={<HelpTopicsLoader />}>
            {ticketInteraction?.workflowId ? (
              <HelpTopics
                workflowId={ticketInteraction?.workflowId}
                workflowVersion={ticketInteraction?.workflowVersion}
              />
            ) : (
              <div />
            )}
          </Suspense>
        </ErrorBoundary>
      </Wrapper>
      <Suspense fallback={<div />}>
        <ConflictModel />
      </Suspense>
    </InitializeTicket>
  )
}

export default connectTicketContext(
  [
    'contacts?',
    'currentContact?',
    'currentStep?',
    'route',
    'ticketInteractions?',
    'tickets?',
    'workflows?',
    'workflowStepGroupSteps?',
    'variables?',
  ],
  [
    'getContact',
    'getInteractionFinalizeInfo',
    'getLocation',
    'getWorkflowStepGroups',
    'updateContact',
    'updateNotes',
  ],
)(WorkTicket)
