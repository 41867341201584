import { format } from 'date-fns'
import React from 'react'

import Icon from 'happitu/src/components/Icon'
import Pill from 'happitu/src/components/Layout/Pill'
import { SHORT_HAND_DATE_FORMAT } from 'happitu/src/helpers/dateHelpers'
import { checkExpiresSoon, checkIsExpired } from 'happitu/src/helpers/helpTopicHelpers'

interface Props {
  expiresAt: string | null
}

const ExpirationPill = ({ expiresAt }: Props) => {
  const date = !!expiresAt ? new Date(expiresAt) : null
  const isExpired = checkIsExpired(expiresAt)
  const expiresSoon = checkExpiresSoon(expiresAt)
  const type = isExpired ? 'error' : expiresSoon ? 'warning' : undefined
  const label = !!date
    ? `${isExpired ? 'Expired' : 'Expires'} ${format(date, SHORT_HAND_DATE_FORMAT)}`
    : 'No Expiration Date'

  return (
    <Pill style={{ marginTop: '0.5em' }} type={type}>
      <Icon type="stopwatch" style={{ marginRight: '0.25em' }} /> {label}
    </Pill>
  )
}

export default ExpirationPill
