import React, { Suspense } from 'react'

import RedirectToOrganization from './App/RedirectToOrganization'
import AppInitializer from './AppInitializer'

import ErrorBoundary from 'happitu/src/components/ErrorBoundary'
import { NotFound } from 'happitu/src/components/ErrorPages'
import AppLoader from 'happitu/src/components/Loaders/AppLoader'
import createRouter from 'happitu/src/createRouter'
import RouterErrorMessage from 'happitu/src/error-boundaries/RouterErrorMessage'
import PortableTicketing from 'happitu/src/pages/portable-ticketing'
import Ticketing from 'happitu/src/pages/ticketing'

const TicketBin = React.lazy(
  () => import(/* webpackChunkName: "TicketBin" */ './TicketBin'),
)

const Reporting = React.lazy(
  () => import(/* webpackChunkName: "Reporting" */ './Reporting'),
)

export default createRouter({
  views: {
    home: RedirectToOrganization,
    app: {
      index: TicketBin,
      insights: Reporting,
      lookup: Reporting,
      dashboard: Reporting,
      manage: React.lazy(
        () => import(/* webpackChunkName: "Manage" */ 'happitu/src/routers/ManageRouter'),
      ),
      tickets: {
        index: TicketBin,
        success: Ticketing,
        view: TicketBin,
        new: Ticketing,
        work: Ticketing,
        continue: Ticketing,
        findOrCreate: Ticketing,
      },
      portable: {
        new: PortableTicketing,
        work: PortableTicketing,
        lookup: PortableTicketing,
        success: PortableTicketing,
      },
      workflow: React.lazy(
        () =>
          import(
            /* webpackChunkName: "WorkflowDesigner" */ 'happitu/src/routers/WorkflowRouter'
          ),
      ),
    },
  },
})(({ children, route }) => {
  if (!route) {
    return <NotFound />
  } else if (!route.params.organizationId) {
    return <RedirectToOrganization />
  } else {
    return (
      <ErrorBoundary errorComponent={RouterErrorMessage}>
        <AppInitializer>
          <Suspense fallback={<AppLoader />}>{children}</Suspense>
        </AppInitializer>
      </ErrorBoundary>
    )
  }
})
